import { Link } from 'gatsby'
import React from 'react'
import { buildImageObj, cn, getNewsUrl } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import BlockText from './block-text'
import Icon from './icons'
import ttDefault from '../img/tomasz-default.png'

import styles from './news-post-preview.module.css'

function NewsPostPreview (props) {

  const categoryMap = {
    'featured': 'Featured',
    'portfolio': 'Portfolio Co',
    'redpoint': 'Redpoint',
    'team': 'Team',
    'events': 'Events',
    'videos': 'Videos',
  }

  let displayCategory = null;
  if (props.categories.length > 0) {
    displayCategory = categoryMap[props.categories[0]]
  }

  return (
    <a className={styles.root} href={props.link} target="_blank">
      <div className={styles.leadMediaThumb}>
        {props.mainImage && props.mainImage.asset && (
          <img
            src={imageUrlFor(buildImageObj(props.mainImage))
              // .width(580)
              // .height(400)
              .url()}
            alt={props.mainImage.alt}
          />
        )}
        {!props.mainImage && props.publication === "Tomtunguz.com" && (
          <img src={ttDefault} alt={props.publication} />
        )}
      </div>
      {displayCategory && (
        <p className={styles.category}>{displayCategory}</p>
      )}
      <h5 className={`${styles.headline} ${styles.bullet}`}>{props.title}</h5>
      <p className={styles.publication}>{props.publication}</p>
      {props._rawExcerpt && (
        <div className={styles.excerpt}>
          <BlockText blocks={props._rawExcerpt} />
        </div>
      )}
      <span className={styles.readmore}>Read more <Icon symbol="chevronright" /></span>
    </a>
  )
}

export default NewsPostPreview
