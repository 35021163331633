import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Person from '../components/person'
import SEO from '../components/seo'
import Layout from '../containers/layout'

export const query = graphql`
  query PersonTemplateQuery($id: String!) {
    person: sanityPerson(id: { eq: $id }) {
        _rawBio
        name
        position
        id
        heroImage {
          asset {
            _id
            url
          }
        }
        socialLinkedin
        socialInstagram
        socialMedium
        socialTwitter
        relatedCompanies {
          title
          _id
          _key
          slug {
            current
          }
          _rawExcerpt
          link
          logoColor {
            asset {
              _id
              url
            }
          }
          logo {
            asset {
              _id
              url
            }
          }
          brandColor {
            hex
          }
          mainImage {
            asset {
              _id
              url
            }
          }
          sectors {
            _id
            title
          }
          stage {
            _id
            title
          }
        }
        relatedArticles {
          id
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
          title
          link
          publication
          categories
        }
        metaTitle
        metaDescription
        metaKeywords
    }
  }
`

const PersonTemplate = props => {
  const { data, errors } = props
  const person = data && data.person

  let hideColor = false;
  if (person.relatedArticles.length === 0) {
    hideColor = true;
  }

  return (
    <Layout hideColor={hideColor}>
      {errors && <SEO title='GraphQL Error' />}
      {person && <SEO title={person.metaTitle || person.name} description={person.metaDescription} keywords={person.metaKeywords} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {!errors && person && <Person {...person} />}
    </Layout>
  )
}

export default PersonTemplate
