import { Link } from 'gatsby'
import React from 'react'
import NewsPostPreview from './news-post-preview'
import Container from '../components/container'
import Icon from './icons'

import styles from './news-post-preview-grid.module.css'

function NewsPostPreviewGrid (props) {
  return (
    <div className={`${styles.root} ${styles[props.display]}`} id="news">
      <Container>
        <div className={styles.titleBar}>
          <div>
            {props.title && (
              <h3 className={`${styles.headline} ${styles.bullet}`}>
                {props.title}
              </h3>
            )}
            <p className={styles.subtitle}>{props.description}</p>
          </div>
          {props.socials && (
            <div className={styles.socials}>
              {props.socials.socialLinkedin && (
                <a href={props.socials.socialLinkedin} target="_blank"><Icon symbol='linkedin' /></a>
              )}
              {props.socials.socialInstagram && (
                <a href={props.socials.socialInstagram} target="_blank"><Icon symbol='instagram' /></a>
              )}
              {props.socials.socialMedium && (
                <a href={props.socials.socialMedium} target="_blank"><Icon symbol='medium' /></a>
              )}
              {props.socials.socialTwitter && (
                <a href={props.socials.socialTwitter} target="_blank"><Icon symbol='twitter' /></a>
              )}
            </div>
          )}
        </div>
      
        <div className={styles.grid}>
          {props.portfolioNodes &&
            props.portfolioNodes.map(node => (
              <div key={node.id}>
                <NewsPostPreview {...node} />
              </div>
            ))}

          {props.redpointNodes &&
            props.redpointNodes.map(node => (
              <div key={node.id}>
                <NewsPostPreview {...node} />
              </div>
            ))}

          {props.teamNodes &&
            props.teamNodes.map(node => (
              <div key={node.id}>
                <NewsPostPreview {...node} />
              </div>
            ))}

          {props.eventsNodes &&
            props.eventsNodes.map(node => (
              <div key={node.id}>
                <NewsPostPreview {...node} />
              </div>
            ))}

          {props.nodes &&
            props.nodes.map(node => (
              <div key={node.id}>
                <NewsPostPreview {...node} />
              </div>
            ))}
        </div>

        <div className={styles.footBar}>
          <div>
            {props.browseMoreHref && (
              <Link className={`${styles.button} ${styles.primary}`} to={props.browseMoreHref}>See all news</Link>
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

NewsPostPreviewGrid.defaultProps = {
  title: '',
  socials: false,
  //  nodes: [],
  portfolioNodes: [],
  redpointNodes: [],
  teamNodes: [],
  eventsNodes: [],
  browseMoreHref: ''
}

export default NewsPostPreviewGrid
