import React from 'react'
import Container from './container'

import styles from './hero.module.css'

function Hero ({ children, image }) {
  return (
    <div className={`${styles.root} ${styles.investor}`} style={{backgroundImage: `url(${image})`}}>
      <Container>
        {children}
      </Container>
    </div>
  )
}

export default Hero
