// import { format, distanceInWords, differenceInDays } from 'date-fns'
import React from 'react'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import BlockContent from './block-content'
import Container from './container'
import Hero from './hero-investor'
import CompanyPreview from './company-preview'
import NewsPostPreviewGrid from '../components/news-post-preview-grid'
import Icon from './icons'

import styles from './person.module.css'

function Person (props) {
  const { _rawBio, name, position, heroImage, socialLinkedin, socialInstagram, socialMedium, socialTwitter, relatedCompanies, relatedArticles } = props
  const names = name.split(' ');
  const firstName = names[0];
  
  return (
    <>
        <Hero image={heroImage && imageUrlFor(buildImageObj(heroImage)).url()}>
            <div>
                <h1 className={`${styles.headline} ${styles.bullet}`}>
                    {name}
                </h1>
                <h4>
                  {position}
                </h4>
            </div>
        </Hero>

        <Container size="large">
            <section className={styles.longform}>
                {relatedCompanies.length > 0 && (
                <aside>
                    <h6 className={`${styles.headline} ${styles.bullet}`}>Investments at Redpoint</h6>
                    <div className={styles.companies}>
                        {relatedCompanies.map(node => (
                          <CompanyPreview plain={true} border={true} key={node._id} {...node} />
                        ))}
                    </div>
                </aside>
                )}
                <div className={`${styles.mainContent} ${relatedCompanies.length === 0 ? styles.singleColumn : ''}`}>
                    {_rawBio && <BlockContent blocks={_rawBio || []} />}
                    {(socialLinkedin || socialInstagram || socialMedium || socialTwitter) && (
                      <div className={styles.socials}>
                        <h6 className={`${styles.headline} ${styles.bullet}`}>Contact {firstName}</h6>
                        <div className={styles.icons}>
                          {socialLinkedin && (
                            <a href={socialLinkedin} target="_blank"><Icon symbol='linkedin' /></a>
                          )}
                          {socialInstagram && (
                            <a href={socialInstagram} target="_blank"><Icon symbol='instagram' /></a>
                          )}
                          {socialMedium && (
                            <a href={socialMedium} target="_blank"><Icon symbol='medium' /></a>
                          )}
                          {socialTwitter && (
                            <a href={socialTwitter} target="_blank"><Icon symbol='twitter' /></a>
                          )}
                        </div>
                      </div>
                    )}
                </div>
            </section>
        </Container>

        {relatedArticles.length > 0 && (
          <NewsPostPreviewGrid
            title='Related'
            showSocials={false}
            nodes={relatedArticles}
            browseMoreHref='/news/'
          />
        )}
    </>
  )
}

export default Person
